import { Link } from 'gatsby';
import React from 'react';
import HorizontalCarousel from '../../Carousel/HorizontalCarousel';
import './HomeBanner.scss';

interface HomeBannerProps {
    // carouselPosts: Record<string, Frontmatter>;
    horizontalCarouselTopics: TopicDetails[];
}

const HomeBanner: React.FC<HomeBannerProps> = (props: HomeBannerProps) => {
    return (
        <div className="home-banner">
            {/* <div className="home-banner__background-wave"></div> */}
            <div className="home-banner__content">
                <div className="home-banner__content__details">
                    <h1> Solidity na Prática</h1>

                    <p>
                        - Aprenda Solidity praticando<br/>
                        - Do básico ao avançado<br/>
                        - Crie aplicações completas<br/>
                        - Crie contratos inteligentes<br/>
                        - Desenvolva sua própria criptomoeda<br/>
                        - Aprenda criar tokens para jogos NFT<br/>
                        - Aprenda criar coleções NFT<br/>
                    </p>
                </div>
                <div className="home-banner__content__image">
                    <HorizontalCarousel
                        slidesNum={props.horizontalCarouselTopics.length}
                    >
                        {[...props.horizontalCarouselTopics]
                            .sort()
                            .sort(() => Math.random() - 0.5)
                            .map((topic, i) => (
                                <Link
                                    key={topic.title}
                                    className="carousel-topic"
                                    to={`/topics/${topic.url}`}
                                >
                                    <div className="carousel-topic__logo-wrapper">
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: topic.logo || ''
                                            }}
                                        ></span>
                                    </div>
                                    <div className="carousel-topic__title">
                                        <h4>{topic.title}</h4>
                                    </div>
                                </Link>
                            ))}
                    </HorizontalCarousel>
                </div>
            </div>
        </div>
    );
};

export default HomeBanner;
